import { defineStore } from 'pinia';
import { ref, computed, toRefs } from 'vue';
import { ProfessionalismTest, ProfessionalismTestResponse } from '@graphql/types/onboarding';
import { SHOULD_SHOW_PROFESSIONALISM_TEST_QUERY } from '@graphql/queries/onboarding';
import { ProfessionalismStatus } from '@graphql/enums/onboarding';
import { useUrqlStore } from '@stores/useUrqlStore';

export const useInvestorTestStore = defineStore('investorTestStore', () => {
  
  const { gqlClient } = toRefs(useUrqlStore());
  
  // State
  const professionalismTest = ref<ProfessionalismTest | null>(null);

  const isProfessionalismTestRejected = computed(() => {
    return professionalismTest.value?.overall_status === ProfessionalismStatus.REQUEST_REJECTED;
  });

  const isProfessionalismTestInExperienced = computed(() => {
    return professionalismTest.value?.overall_status === ProfessionalismStatus.INEXPERIENCED;
  });

  const isProfessionalismTestPendingRequest = computed(() => {
    return professionalismTest.value?.overall_status === ProfessionalismStatus.PENDING_REQUEST;
  });

  // Actions
  const setProfessionalismTest = (data: ProfessionalismTest) => {
    professionalismTest.value = data;
  };

  const getProfessionalismTest = async (): Promise<ProfessionalismTestResponse> => {
    try {
      const result = await gqlClient.value
        .query<ProfessionalismTestResponse>(SHOULD_SHOW_PROFESSIONALISM_TEST_QUERY, {})
        .toPromise();

      if (result.data?.professionalismTest) {
        setProfessionalismTest(result.data.professionalismTest);
        return result.data;
      }
      throw new Error('Fout tijdens het ophalen van de investeerderstoets');
    } catch (error) {
      console.error('Fout tijdens het ophalen van de investeerderstoets', error);
      throw error;
    }
  };

  return {
    // State
    professionalismTest,
    isProfessionalismTestRejected,
    isProfessionalismTestInExperienced,
    isProfessionalismTestPendingRequest,

    // Actions
    setProfessionalismTest,
    getProfessionalismTest,
  };
});
